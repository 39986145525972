import { PayloadAction, TriggerAction } from '../../../../core/AppActions'
import { Filter } from '../../../../model/Filter'
import { GeoJSON } from 'geojson'

export enum RouteSecondaryHeatmapActionTypes {
	CREATE_SECONDARY_HEATMAP_ROUTE_REQUEST = 'CREATE_SECONDARY_HEATMAP_ROUTE_REQUEST',
	CREATE_SECONDARY_HEATMAP_ROUTE_SUCCESS = 'CREATE_SECONDARY_HEATMAP_ROUTE_SUCCESS',
	CREATE_SECONDARY_HEATMAP_ROUTE_FAILURE = 'CREATE_SECONDARY_HEATMAP_ROUTE_FAILURE',
	CREATE_SECONDARY_HEATMAP_FILTER_RESET = 'CREATE_SECONDARY_HEATMAP_FILTER_RESET',
	CREATE_SECONDARY_HEATMAP_ROUTE_RESET = 'CREATE_SECONDARY_HEATMAP_ROUTE_RESET',
}

export type SecondaryHeatmapRouteRequestAction = PayloadAction<
	RouteSecondaryHeatmapActionTypes.CREATE_SECONDARY_HEATMAP_ROUTE_REQUEST,
	{ filter: Filter; geoJson?: GeoJSON | null }
>
export type SecondaryHeatmapRouteSuccessAction = PayloadAction<RouteSecondaryHeatmapActionTypes.CREATE_SECONDARY_HEATMAP_ROUTE_SUCCESS, any>
export type SecondaryHeatmapRouteFailureAction = PayloadAction<RouteSecondaryHeatmapActionTypes.CREATE_SECONDARY_HEATMAP_ROUTE_FAILURE, any>
export type SecondaryHeatmapFilterResetAction = TriggerAction<RouteSecondaryHeatmapActionTypes.CREATE_SECONDARY_HEATMAP_FILTER_RESET>
export type SecondaryHeatmapRouteResetAction = TriggerAction<RouteSecondaryHeatmapActionTypes.CREATE_SECONDARY_HEATMAP_ROUTE_RESET>

export const secondaryHeatmapRouteRequestAction = (filter: Filter, geoJson?: GeoJSON | null): SecondaryHeatmapRouteRequestAction => {
	return {
		type: RouteSecondaryHeatmapActionTypes.CREATE_SECONDARY_HEATMAP_ROUTE_REQUEST,
		payload: {
			filter: filter,
			geoJson: geoJson,
		},
	}
}

export const secondaryHeatmapRouteSuccessAction = (response: any): SecondaryHeatmapRouteSuccessAction => {
	return {
		type: RouteSecondaryHeatmapActionTypes.CREATE_SECONDARY_HEATMAP_ROUTE_SUCCESS,
		payload: response,
	}
}

export const secondaryHeatmapRouteFailureAction = (errorMessage: string): SecondaryHeatmapRouteFailureAction => {
	return {
		type: RouteSecondaryHeatmapActionTypes.CREATE_SECONDARY_HEATMAP_ROUTE_FAILURE,
		payload: errorMessage,
	}
}

export const secondaryHeatmapFilterResetAction = (): SecondaryHeatmapFilterResetAction => {
	return {
		type: RouteSecondaryHeatmapActionTypes.CREATE_SECONDARY_HEATMAP_FILTER_RESET,
	}
}

export const secondaryHeatmapRouteResetAction = (): SecondaryHeatmapRouteResetAction => {
	return {
		type: RouteSecondaryHeatmapActionTypes.CREATE_SECONDARY_HEATMAP_ROUTE_RESET,
	}
}

export type SecondaryHeatmapRouteActions =
	| SecondaryHeatmapRouteRequestAction
	| SecondaryHeatmapRouteSuccessAction
	| SecondaryHeatmapRouteFailureAction
	| SecondaryHeatmapFilterResetAction
	| SecondaryHeatmapRouteResetAction
